import React, { useState, useEffect } from 'react';
import { useIntl, navigate } from 'gatsby-plugin-intl';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Button from '../Forms/Button/Button';
import ImageBlock from '../ImageBlock/ImageBlock';
import './style.css';
import arrR from '../../assets/icons/arrR.svg';
import SliderBeato from './SliderBeato/SliderBeato';
import BlockLayout from '../../layaouts/BlockLayout/BlockLayout';
import MainCarusel from '../Forms/MainCrusel/MainCarusel';
import Timeline from '../Timeline/Timeline';
import Carusel from '../Forms/Carusel/Carusel';
import MetaTags from '../Header/MetaTags';

function IlBeato({ data }) {
  const breakpoints = useBreakpoint();
  const intl = useIntl();
  const {
    foundationHero, descriptionParagraph, founders, timeline,
  } = data[intl.locale].acf;
  const { firstImageSlider } = data[intl.locale].acf;
  const { secondImageSlider } = data[intl.locale].acf;
  const { yoast_head_json } = data[intl.locale];

  const [collapse, setCollapse] = useState(true);
  const btnLabel = collapse
    ? intl.formatMessage({ id: 'btn_vedi_di_piu' })
    : intl.formatMessage({ id: 'btn_vedi_di_meno' });

  useEffect(() => {
    const mylist = document.getElementById('beato');
    const divs = mylist.children;
    const listitems = [];
    for (let i = 0; i < divs.length; i++) {
      listitems.push(divs.item(i));
    }

    listitems.sort((a, b) => {
      const compA = a.getAttribute('id');
      const compB = b.getAttribute('id');
      return compA < compB ? -1 : compA > compB ? 1 : 0;
    });
    for (let i = 0; i < listitems.length; i++) {
      mylist.appendChild(listitems[i]);
    }
  }, []);

  return (
    <>
      <MetaTags data={yoast_head_json} />
      <div id="beato" style={{ backgroundColor: 'white' }}>
        {(foundationHero.title && foundationHero.imageBackgroundFoundationHero) && (
        <div id={foundationHero.positionFoundationHero}>
          <ImageBlock
            type="3"
            title={foundationHero.title}
            img={foundationHero.imageBackgroundFoundationHero}
          />
        </div>
        )}

        {(descriptionParagraph.paragraphTitle && descriptionParagraph.contentVisibile) && (
        <div className="collapsableTextBox" id={descriptionParagraph.positionParagraph}>
          <h1 className="titleBox">{descriptionParagraph.paragraphTitle}</h1>
          {descriptionParagraph.contentVisibile
          && descriptionParagraph.contentHidden && (
            <div className="collapsable">
              <div className="collapsableText">
                <span
                  dangerouslySetInnerHTML={{
                    __html: collapse
                      ? descriptionParagraph.contentVisibile
                      : descriptionParagraph.contentVisibile.concat(
                        descriptionParagraph.contentHidden,
                      ),
                  }}
                />
              </div>

              {descriptionParagraph.contentHidden && (
                <span style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
                >
                  <Button
                    handleClick={() => {
                      setCollapse(!collapse);
                    }}
                    data-action="cta scopri"
                    data-name-cta={btnLabel}
                    id="collapseBtn"
                    label={btnLabel}
                    style={{
                      borderRadius: 0,
                      textTransform: 'uppercase',
                      color: 'black',
                      fontStyle: 'normal',
                      fontWeight: 300,
                      fontSize: '14px',
                      lineHeight: '21px',
                    }}
                  >
                    {collapse ? (
                      <img
                        alt="accordionIMG"
                        src={arrR}
                        style={{
                          marginLeft: 6,
                          width: '16px',
                          height: '16px',
                        }}
                      />
                    ) : (
                      <img
                        alt="accordionIMG"
                        src={arrR}
                        style={{
                          marginLeft: 6,
                          width: '16px',
                          height: '16px',
                          transform: 'rotate(-180deg)',
                        }}
                      />
                    )}
                  </Button>
                </span>
              )}
            </div>
          )}
        </div>
        )}

        {(firstImageSlider.firstImageSlider.length > 0) && (
        <div id={firstImageSlider.positionFirstImageSlider}>
          <SliderBeato data={firstImageSlider.firstImageSlider} />
        </div>
        )}

        {(timeline.yearsSlide.length > 0) && (
        <div id={timeline.positionTimeline}>
          <Timeline data={timeline} />
        </div>
        )}

        {secondImageSlider.secondImageSlider.length > 0 && (
        <div id={secondImageSlider.positionSecondImageSlider}>
          <SliderBeato data={secondImageSlider.secondImageSlider} />
          <br />
          <br />
        </div>
        )}

        {(founders.blockTitleFounders && founders.foundersSlider.length > 0) && (
        <div id={founders.positionFounders}>
          <BlockLayout
            icon={founders.blockLogoFounders}
            title={founders.blockTitleFounders}
            btnLabel={founders.buttonLabelFounders}
            bgColor="white"
            href={founders.buttonUrlFounders}
          >
            <Carusel
              component="IlBeato"
              cards={founders.foundersSlider}
              width={!breakpoints.md ? 348 : 256}
              height={!breakpoints.md ? 488 : 360}
              initialSlide={1}
            />
          </BlockLayout>
        </div>
        )}

      </div>
    </>
  );
}

export default IlBeato;
