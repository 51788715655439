import React, { useState, useRef, useLayoutEffect } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import './style.css';
import timeLogo from '../../assets/icons/timeline.svg';
import BlockLayout from '../../layaouts/BlockLayout/BlockLayout';
import arrR from '../../assets/icons/arrR.svg';

function Timeline({ data }) {
  const {
    yearsSlide: dates,
  } = data;

  const breakpoints = useBreakpoint();
  const [position, setPosition] = useState(1);
  const [disableLeftArrow, setDisableLeftArrow] = useState(true);
  const [disableRightArrow, setDisableRightArrow] = useState(false);

  const items = useRef(null);
  const years = useRef(null);
  let counter = 0;

  useLayoutEffect(() => {
    for (let index = 0; index < items.current.children.length; index++) {
      items.current.children[index].style.setProperty('--offset', index + 1);
    }
    for (let index = 0; index < years.current.children.length - 1; index++) {
      years.current.children[index].style.setProperty('--offset', index + 1);
    }
    items.current.onScroll = () => {

    };
  }, []);

  const handleClickOnCards = (index) => {
    setPosition(index);
    items.current.style.setProperty('--position', index);
    years.current.style.setProperty('--positionY', index);
    // items.current.scrollLeft += scrolledPixelsContent;
    // scrolledPixelsContent=0;
  };
  const handleClickOnYears = (index) => {
    setPosition(index);
    years.current.scrollLeft = 0;
    items.current.style.setProperty('--position', index);
    years.current.style.setProperty('--positionY', index);
  };

  const handleClickArrows = (direction) => {
    if (position === items.current.children.length && direction === 'r') return;
    if (position === 1 && direction === 'l') return;
    if (direction === 'l') {
      setPosition(position - 1);

      items.current.style.setProperty('--position', position - 1);
      years.current.style.setProperty('--positionY', position - 1);
    }
    if (direction === 'r') {
      setPosition(position + 1);
      items.current.style.setProperty('--position', position + 1);
      years.current.style.setProperty('--positionY', position + 1);
    }
  };

  return (
    <BlockLayout
      icon={timeLogo}
      title="Timeline"
      btnLabel=""
      bgColor="#f8f9fa"
      style={{ margin: '60px auto' }}
    >
      <div className="yearsContainer">
        <div ref={years} id="lineYears">
          {dates
            && dates.map((date, index) => (
              <div
                className={
                    position - 1 === index ? 'year--selected ' : 'year'
                  }
                key={index + 1}
                onClick={() => handleClickOnYears(index + 1)}
              >
                {date.year}
                {position - 1 === index && <span className="dotTimeline" />}
              </div>
            ))}
          <div className="bottomLine" />
        </div>
      </div>
      <div className="separator" style={{ width: '100%' }}>
        <div className="lineSeparator" />
      </div>

      <div className="timelineContainer">
        <div ref={items} id="carousel">
          {dates
            && dates.map((dat, index) => {
              dat.cardImage !== false && counter++;
              return (
                <div

                  className="item"
                  key={index + 1}
                  onClick={() => handleClickOnCards(index + 1)}
                >
                  {dat.cardImage === false ? (
                    <div className="fullCard">
                      <div className="fullCard_title">{dat.year}</div>
                      <div className="fullCard_content">{dat.content}</div>
                    </div>
                  ) : (
                    <div
                      className="Card"
                      style={{
                        flexDirection:
                          counter % 2 === 0 ? 'column' : 'column-reverse',
                      }}
                    >
                      <div className="upCard">
                        <div className="Card_title">{dat.year}</div>
                        <div className="Card_content">{dat.content}</div>
                      </div>
                      <div className="downCard">
                        <img
                          alt="cardTimelineImg"
                          className="cardTimelineIMG"
                          src={dat.cardImage}
                        />
                        <div className="cardTimelineOverlayIMG" style={{ backgroundColor: counter % 2 === 0 ? '#A6322E' : '#D75F2B' }} />
                        <div className="cardTimelineOverIMG" style={{ backgroundColor: counter % 2 === 0 ? '#A6322E' : '#D75F2B' }}>
                          <div className="cardTimelineOverIMG_content">
                            {dat.imageText}
                          </div>
                          <div className="cardTimelineOverIMG_author">
                            <div
                              style={{
                                width: 33,
                                hight: '64%',
                                borderTop: '1px solid white',
                                alignSelf: 'center',
                                marginRight: 10,
                              }}
                            />
                            {dat.imageAuthor}
                            <div
                              style={{
                                width: 33,
                                hight: '64%',
                                borderTop: '1px solid white',
                                alignSelf: 'center',
                                marginLeft: 10,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <div className="caruselArrows">
        <a onClick={() => handleClickArrows('l')}>
          <img
            alt="accordionIMG"
            src={arrR}
            className={position - 1 <= 0 ? 'mainCaruselArrowDisabled' : 'mainCaruselArrow'}
            style={{
              transform: 'rotate(-180deg)',
            }}
          />
        </a>
        <div className="caruselBottomBar">
          {dates
                && dates.map((date, index) => (
                  <div
                    className={
                        position - 1 === index ? 'bottomBarSectionActive ' : 'bottomBarSection'
                    }
                    key={index + 1}
                    style={{ width: `${(breakpoints.md ? 200 : 625) / dates.length}px` }}
                  />

                ))}
        </div>

        <a onClick={() => handleClickArrows('r')}>
          <img alt="accordionIMG" src={arrR} className={(dates && position + 1 > dates.length) ? 'mainCaruselArrowDisabled' : 'mainCaruselArrow'} />
        </a>
      </div>
    </BlockLayout>
  );
}

export default Timeline;
